import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {OperationConfigType} from "../../../../../../../server/src/db/classes/operation-config.type";
import {DataService} from "src/app/Services/data.service";
import {IntegrationService} from "src/app/Services/integration.service";
import {
  NotificationIntegrationType,
  SlackIntegration
} from "../../../../../../../server/src/db/classes/notification-integration.type";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";
import {SlackIntegrationService} from "src/app/Services/slack-integration.service";

@Component({
  selector: "app-slack-integration-config",
  templateUrl: "./slack-integration-config.component.html",
  styleUrls: ["./slack-integration-config.component.scss"]
})
export class SlackIntegrationConfigComponent implements OnInit {
  @Input() integration: SlackIntegration;
  @Input() sourceConfig: SourceConfigDialogData;
  @Input() selectedIntegration: NotificationIntegrationType | null;
  @Output() selectedIntegrationChange = new EventEmitter<NotificationIntegrationType | null>();

  public isConfirmDelete: boolean = false;
  public isLoading = false;
  public channels: {id: string; name: string}[] = [];

  constructor(
    private dataService: DataService,
    private integrationService: IntegrationService,
    private slackIntegrationService: SlackIntegrationService
  ) {}

  ngOnInit() {
    if (this.integration.accessToken) {
      this.loadChannels();
    }
  }

  private get operationConfigId(): string {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        return this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
      }
      case OperationConfigType.Business: {
        return this.sourceConfig.businessId;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public async toggleEnable(newStatus: boolean): Promise<void> {
    if (newStatus && !this.integration.channelId) {
      return;
    }

    const status = await this.integrationService.toggleEnable(
      this.operationConfigId,
      NotificationIntegrationType.Slack,
      this.sourceConfig.type,
      newStatus
    );
    this.integration.enabled = status;
  }

  public confirmDelete(): void {
    this.isConfirmDelete = true;
  }

  public cancelDelete(): void {
    this.isConfirmDelete = false;
  }

  public async disconnectSlack(): Promise<void> {
    const result = await this.integrationService.removeAccessToken(
      this.operationConfigId,
      NotificationIntegrationType.Slack
    );
    if (result) {
      this.integration.accessToken = null;
      this.integration.teamId = null;
      this.integration.channelId = null;
      this.toggleEnable(false);
      this.updateSelectedIntegration(null);
    } else {
      this.cancelDelete();
    }
  }

  public async loadChannels(): Promise<void> {
    this.isLoading = true;
    try {
      this.channels = await this.slackIntegrationService.getSlackChannels(this.integration.accessToken);
    } catch (e) {
      console.error("Error loading Slack channels:", e);
    }
    this.isLoading = false;
  }

  public updateSelectedIntegration(type: NotificationIntegrationType | null) {
    this.selectedIntegration = type;
    this.selectedIntegrationChange.emit(this.selectedIntegration);
  }

  public async selectChannel(channelId: string): Promise<void> {
    this.integration.channelId = channelId;
    await this.slackIntegrationService.manageSlackChannelId(
      this.operationConfigId,
      channelId,
      this.integration.accessToken
    );
  }
}
