import {ObjectId} from "mongodb";

export enum NotificationIntegrationType {
  WhatsApp = "Whatsapp",
  Sms = "SMS",
  Hubspot = "Hubspot",
  PipeDrive = "PipeDrive",
  Workiz = "Workiz",
  Slack = "Slack"
}

export type NotificationIntegrations = NotificationIntegration[];
export type NotificationIntegration =
  | WhatsAppIntegration
  | SmsIntegration
  | HubspotIntegration
  | PipeDriveIntegration
  | WorkizIntegration
  | SlackIntegration;

export interface NotificationIntegrationBase {
  type: NotificationIntegrationType;
  enabled: boolean;
}

export interface WhatsAppIntegration extends NotificationIntegrationBase {
  type: NotificationIntegrationType.WhatsApp;
  whatsappChatId$: ObjectId | null;
  whatsappChatName: string | null;
  whatsappChatsCode: string | null;
}

export interface SmsIntegrationPhone {
  number: string;
}

export interface SmsIntegration extends NotificationIntegrationBase {
  type: NotificationIntegrationType.Sms;
  phone?: SmsIntegrationPhone[];
}

export interface HubspotIntegration extends NotificationIntegrationBase {
  type: NotificationIntegrationType.Hubspot;
  accessToken?: string | null;
}

export interface PipeDriveIntegration extends NotificationIntegrationBase {
  type: NotificationIntegrationType.PipeDrive;
  accessToken?: string | null;
}

export interface WorkizIntegration extends NotificationIntegrationBase {
  type: NotificationIntegrationType.Workiz;
  accessToken?: string | null;
  apiSecret?: string | null;
}

export interface SlackIntegration extends NotificationIntegrationBase {
  type: NotificationIntegrationType.Slack;
  teamId?: string | null;
  accessToken?: string | null;
  channelId?: string | null;
}

export interface NotificationIntegrationDefaultParams {
  name: string;
  logoUrl: string;
  description: string;
}

export class NotificationIntegrationDefault {
  private integration: NotificationIntegration;

  constructor(integration: NotificationIntegration) {
    this.integration = integration;
  }

  get name(): string {
    return this.getDefaults().name;
  }

  get logoUrl(): string {
    return this.getDefaults().logoUrl;
  }

  get description(): string {
    return this.getDefaults().description;
  }

  private getDefaults(): NotificationIntegrationDefaultParams {
    switch (this.integration.type) {
      case NotificationIntegrationType.WhatsApp:
        return {
          name: "WhatsApp",
          description: "Collaborate with your team using WhatsApp. receive all leads in a single group chat.",
          logoUrl: "assets/integrations/whatsapp-logo.png"
        };
      case NotificationIntegrationType.Sms:
        return {
          name: "SMS",
          description: "Get Notification directly to your phone",
          logoUrl: "assets/integrations/sms-logo.png"
        };
      case NotificationIntegrationType.Hubspot:
        return {
          name: "Hubspot",
          description: "Get leads created in your CRM",
          logoUrl: "assets/integrations/hubspot-logo.svg"
        };
      case NotificationIntegrationType.PipeDrive:
        return {
          name: "Pipe Drive",
          description: "Get leads created in your CRM",
          logoUrl: "assets/integrations/pipedrive-logo.png"
        };
      case NotificationIntegrationType.Workiz:
        return {
          name: "Workiz",
          description: "Get leads created in your CRM",
          logoUrl: "assets/integrations/workiz.png"
        };
      case NotificationIntegrationType.Slack:
        return {
          name: "Slack",
          description: "Collaborate with your team using Slack. receive all leads in a single group chat.",
          logoUrl: "assets/integrations/slack-logo.svg"
        };
      default:
        throw new Error("Unsupported operation type");
    }
  }
}
