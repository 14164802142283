<ng-template #login>
  <div class="home-buttons">
    <a class="home-register button" href="/login">Get Started</a>
    <asl-google-signin-button
      type="standard"
      style="padding-left: 30px"
      text="continue_with"
      size="large"
      shape="pill"
      theme="outline"></asl-google-signin-button>
  </div>
</ng-template>

<app-google-popup-login></app-google-popup-login>

<a class="floating-icon call-now" (click)="callNow($event)" href="tel:+1 (213) 693-1377" target="_blank">
  <img src="assets/call-now.svg" alt="Call Now" />
</a>

<ng-template #menu>
  <div class="home-buttons" style="user-select: none">
    <a href="#home" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false">Home</a>
    <a href="#about" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false">Details</a>
    <a href="#pricing" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false">Pricing</a>
    <a href="#testimonials" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false"
      >Customers</a
    >
  </div>
</ng-template>

<div class="home-container" [class.midPageScroll]="!scrolledTop" [class.mobileQuery]="mobileQuery">
  <div class="home-header">
    <header class="home-navbar-interactive">
      <span class="home-logo">
        <a href="#home">
          <dynamic-logo [fontWeight]="700" />
        </a>
      </span>
      <div class="home-desktop-menu">
        <nav class="home-links">
          <ng-template [ngTemplateOutlet]="menu"></ng-template>
        </nav>
        <ng-template [ngTemplateOutlet]="login"></ng-template>
      </div>
      <div class="home-burger-menu" (click)="menuIsOpen = !menuIsOpen">
        <asl-google-signin-button
          type="icon"
          style="padding-right: 30px"
          text="continue_with"
          size="medium"
          shape="circle"
          theme="outline"></asl-google-signin-button>
        <div class="home-close-menu">
          <svg viewBox="0 0 1024 1024" class="home-icon">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
      </div>
      <div class="home-mobile-menu" [class.menuIsOpen]="menuIsOpen">
        <div class="home-nav">
          <div class="home-top">
            <span class="home-logo">
              <dynamic-logo />
            </span>

            <div class="home-close-menu" (click)="menuIsOpen = !menuIsOpen">
              <asl-google-signin-button
                type="icon"
                style="padding-right: 30px"
                text="continue_with"
                size="medium"
                shape="circle"
                theme="outline"></asl-google-signin-button>
              <svg viewBox="0 0 1024 1024" class="home-icon02">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <nav class="home-links1">
            <ng-template [ngTemplateOutlet]="menu"></ng-template>
          </nav>
          <ng-template [ngTemplateOutlet]="login"></ng-template>
        </div>
      </div>
    </header>
  </div>
  <div class="home-hero" id="home">
    <div class="home-hero1 anchor">
      <div class="home-container01">
        <div class="home-details1">
          <div class="home-container02">
            <div class="title-container">
              <h1 class="home-hero-heading heading1">Auto Responder and Follow-ups for your Leads!</h1>

              <div class="home-details-sub-heading-cont">
                <h2 class="home-details-sub-heading">
                  Full setup with just
                  <marker text="two simple clicks!"></marker>
                </h2>

                <h2 class="home-details-sub-heading" style="padding-top: 10px">
                  Reduce response time and close more deals effortlessly.
                </h2>
              </div>
            </div>
            <div class="home-btn-group">
              <a class="home-hero-button button" href="/login">Start for free</a>
            </div>
          </div>
          <img alt="yelp bot" src="assets/bot.webp" class="home-details-image" [attr.draggable]="false" />
        </div>
      </div>
    </div>
  </div>
  <div class="how-does-it-work-cont" id="anchor-about">
    <h2 class="home-details-heading heading2">How does it work?</h2>
    <div class="how-does-it-work">
      <div>
        When a new lead arrives at your Yelp Business, our Virtual Assistant sends a welcome message to collect the
        contact info and follows up if needed.
      </div>
      <div style="padding-top: 4px">Securing more deals, faster than the competition!</div>
    </div>
  </div>
  <div class="home-details anchor" id="about"></div>
  <div class="home-features">
    <div class="home-features-container anchor" id="features">
      <div class="home-features1">
        <div class="home-container04">
          <!--          <feature-card Heading="Optimize Every Engagement" width="450" paddingTopImage="50">-->
          <!--            <ul class="check-list">-->
          <!--              <li class="feature-card-text1">Predefine Welcome messages or use AI</li>-->
          <!--              <li class="feature-card-text1">Control Welcome message with keywords</li>-->
          <!--              <li class="feature-card-text1">Teach AI and it Completely generate the Welcome message</li>-->
          <!--              <li class="feature-card-text1">Customize multiple Follow-ups and decide timing to send</li>-->
          <!--            </ul>-->
          <!--          </feature-card>-->
          <feature-card
            Heading="Customize Every Engagement"
            SubHeading="Optimize your welcome messages. Schedule follow-ups and more."
            width="450"
            paddingTopImage="50"
            OriginalImage="assets/main/config_new.gif"
            Image="assets/main/config_new.gif"></feature-card>
          <feature-card
            Heading="Performance Dashboard - Overview & Analysis"
            OriginalImage="assets/main/original/pc2.png"
            Image="assets/main/pc2.webp"
            paddingTopImage="50"
            SubHeading="Get a detailed analysis and performance overview. Split the data by dates and other parameters to get custom insights."></feature-card>
          <feature-card
            Heading="Lead Control Center"
            OriginalImage="assets/main/original/pc3_noPad.png"
            Image="assets/main/pc3.webp"
            SubHeading="Use our Portal to simplify how you manage and talk to your leads through Yelp. Use our all-in-one lead management solution."></feature-card>
          <feature-card
            Heading="100% Mobile Friendly!"
            SubHeading="Freedom to Work, Anytime, Anywhere! Use your Phone to stay connected. Work smarter on the go."
            width="200"
            OriginalImage="assets/main/original/phone.webp"
            Image="assets/main/original/phone.webp"></feature-card>
        </div>
      </div>
    </div>
  </div>

  <div class="home-counter-section">
    <div class="home-counter">
      <app-counter text="Leads" [number]="49.5" numberPostfix="K+" [timeMs]="1500"></app-counter>
      <app-counter text="Messages" [number]="282" numberPostfix="K+" [timeMs]="2000"></app-counter>
      <app-counter text="Businesses" [number]="600" numberPostfix="+" [timeMs]="1000"></app-counter>
      <app-counter text="Integrations sent" [number]="10" numberPostfix="K+" [timeMs]="1000"></app-counter>
    </div>
  </div>

  <div class="home-pricing">
    <div class="home-pricing1 anchor" id="pricing">
      <div class="home-container05">
        <h2 class="home-pricing-heading heading2">Pricing</h2>
        <span class="home-pricing-sub-heading">
          <div class="cards">
            <img src="assets/payments/stripe.png" />
            <img src="assets/payments/apple-pay.svg" class="no-border" />
            <img src="assets/payments/google-pay.svg" />
            <img src="assets/payments/paypal.svg" />
            <img src="assets/payments/visa.svg" />
            <img src="assets/payments/mastercard-alt.svg" />
            <img src="assets/payments/american-express.svg" />
            <img src="assets/payments/amazon-pay.svg" class="no-border" />
          </div>
        </span>
      </div>
      <div class="home-container06">
        <div class="home-pricing-card">
          <div>
            <div class="home-container14 price_first_part">
              <span class="home-text17 heading3"
                >Everything Included<br />
                <span style="font-size: 18px; font-weight: 500">
                  <span class="underline"> 7 days free trial</span>
                </span>
              </span>
              <span class="home-basic-plan-description"> All Features Included! </span>
            </div>
            <div class="home-container15">
              <span class="home-plan-pricing">99</span>
              <span class="home-text21">$ / source / month</span>
            </div>
          </div>
          <div class="home-container16">
            <div class="home-container11">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features1"> Customize your welcome messages or let AI respond. </span>
            </div>
            <div class="home-container11">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features1"> Customize and Schedule your follow-ups. </span>
            </div>
            <div class="home-container11">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features1"> Create and Save Your Own Quick Replies </span>
            </div>
            <div class="home-container12">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features2"> Leads Management System. </span>
            </div>
            <div class="home-container18">
              <span class="home-checkbox">✔</span>
              <span class="home-basic-plan-features">
                Integrating with:<br />
                HubSpot, Pipedrive, Workiz, Slack, Whatsapp, SMS...
              </span>
            </div>
            <div class="home-container21">
              <span class="home-checkbox">✔</span>
              <span class="home-basic-plan-features3">Invite your team.</span>
            </div>
            <div class="home-container07 price_first_part">
              <!--              <span class="home-text09 heading3">Free Trial</span>-->

              <span class="home-free-plan-description">
                <span class="underline">No </span>
                <span class="underline">commitment, </span>
                <span class="underline">cancel </span>
                <span class="underline">anytime!</span>
              </span>
            </div>
          </div>
          <a href="/login" class="link-button">
            <button class="home-button1 button">Start Trial</button>
          </a>
        </div>
        <div class="home-pricing-card">
          <div>
            <div>
              <div class="home-container22 price_first_part">
                <span class="home-text28 heading3">
                  <span>Premium</span>
                  <br />
                </span>
                <span class="home-pro-plan-description"> Become a Premium Customer </span>
              </div>
              <div class="home-container23">
                <span class="home-plan-pricing">149</span>
                <span class="home-text34">$ / source / month</span>
              </div>
            </div>
            <div class="home-container24">
              <div class="home-container25">
                <span class="home-checkbox">✔</span>
                <span class="home-text36"> All features of Essential plan</span>
              </div>
              <div class="home-container26">
                <span class="home-checkbox">✔</span>
                <span class="home-pro-plan-features">Priority support</span>
              </div>
              <div class="home-container26">
                <span class="home-checkbox">✔</span>
                <span class="home-pro-plan-features">Customer specific development</span>
              </div>

              <!--              <img src="assets/yelp-logo_w_bg.png" width="150px" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home-details anchor" id="testimonials">
    <div class="home-testimonials">
      <h3 class="home-banner-heading heading2">What Our Valued Customers Are Saying</h3>
      <app-testimonials style="display: block; width: 100%"></app-testimonials>
    </div>
  </div>

  <!--  <div class="video-container">-->
  <!--    <iframe-->
  <!--      id="video"-->
  <!--      width="100%"-->
  <!--      height="720"-->
  <!--      src="https://www.youtube.com/embed/a7F8LyE4pfo?si=Gf5tBkkWGlrDGdA8"-->
  <!--      frameborder="0"-->
  <!--      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
  <!--      referrerpolicy="strict-origin-when-cross-origin"-->
  <!--      allowfullscreen></iframe>-->
  <!--  </div>-->

  <div class="home-banner">
    <div class="home-banner1">
      <div class="home-btn-group">
        <a class="home-hero-button button" href="/login">Start now</a>
      </div>

      <h3 class="home-banner-heading heading2">Still have questions?</h3>
      <h3 class="home-banner-heading heading3">Contact us now for a 20 min demo and 7-day free trial</h3>
      <span class="home-banner-sub-heading">
        <div class="main-buttons">
          <!--          <div class="home-btn-group">-->
          <!--            <a class="home-hero-button button2" (click)="contactUs()">Request a Demo</a>-->
          <!--          </div>-->

          <div class="btn-group">
            <a class="home-hero-button button2" (click)="openRequestDemo()">Request a Demo</a>
            <a class="home-hero-button button2" (click)="contactUs()">Contact Us</a>
          </div>
        </div>
      </span>
    </div>
  </div>

  <div class="home-footer">
    <footer class="home-footer1">
      <!--      <div class="home-separator"></div>-->
      <div class="home-container33">
        <div>
          <span class="home-text61"
            >© {{ year }} {{ logoService.hostTextPrimary }} Inc. All Rights Reserved.
            <a href="{{ document.location.origin }}/privacy">Privacy Policy</a>,
            <a href="{{ document.location.origin }}/tos">Term Of Service</a></span
          >
          <br />
          <div class="home-icon-group1">
            <a target="_blank" href="https://instagram.com/nzleads">
              <svg viewBox="0 0 877.7142857142857 1024" class="home-icon12">
                <path
                  d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=61560107116152">
              <svg viewBox="0 0 602.2582857142856 1024" class="home-icon14">
                <path
                  d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
